import { SlideNumber, initializeSliderOnView } from "./slide-func.js";

document.addEventListener("DOMContentLoaded", function () {
  $(function () {
    const iconSliderConfigLargeScreen = {
      perPage: 6,
      perMove: 6,
      pagination: true,
      interval: 3000,
      speed: 1500,
      breakpoints: {
        1199: {
          perPage: 4,
          perMove: 4,
        },
        991: {
          perPage: 3,
          perMove: 3,
        },
        767: {
          pagination: false,
        },
        500: {
          pagination: false,
          perPage: 2,
          perMove: 2,
        },
      },
    };

    const iconSliderConfigSmallScreen = {
      perPage: 2,
      perMove: 2,
      pagination: false,
      interval: 3000,
    };

    if ($("[data-iconslider] li").length >= 6 && $(window).width() > 768) {
      $("[data-iconslider]").each(function () {
        const sliderId = $(this).attr("id");
        // Inicjalizacja slidera dla dużego ekranu
        initializeSliderOnView('.iconSlider', `#${sliderId}`, iconSliderConfigLargeScreen, SlideNumber, true);
      });
    } else if ($("[data-iconslider] li").length > 2 && $(window).width() < 768) {
      $("[data-iconslider]").each(function () {
        const sliderId = $(this).attr("id");
        // Inicjalizacja slidera dla małego ekranu
        initializeSliderOnView('.iconSlider', `#${sliderId}`, iconSliderConfigSmallScreen, SlideNumber);
      });
    }
  });
});
